import React, { useState, createContext, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Footer from "./footer/footer";
import Header from "./header/header";
import Home from "./home/home";
import Containers from "./portfolio/containers";
import Portfolio from "./portfolio/portfolio";
import WeatherSetGo from "./portfolio/weather-set-go";
import Resume from "./resume/resume";
import ScrollToTop from "./scroll-to-top";

export const AppContext = React.createContext();

function App() {

    const [pageTitle, setPageTitle] = useState();
    const [pageTitleSuffix, setPageTitleSuffix] = useState("| Eduardo Juarez Dev Profile");
    
    return (
      <HelmetProvider>
        <BrowserRouter onUpdate={() => window.scrollTo(0, 0)}>
        <Helmet><title>{ pageTitle }</title></Helmet>
        <ScrollToTop />
          <AppContext.Provider value={{ setPageTitle: setPageTitle, pageTitleSuffix: pageTitleSuffix }}>
            <Header />
            <Routes>
              <Route exact path='/' element={<Home />} />
              <Route path='/resume' element={<Resume />} />
              <Route path="/portfolio" element={<Portfolio/> } />
              <Route path='/weather-set-go' element={<WeatherSetGo />} />
              <Route path='/containers' element={<Containers />} />
            </Routes>          
            <Footer />
          </AppContext.Provider>
        </BrowserRouter>
      </HelmetProvider>
    );
  }

export default App;