import React, { useContext, useEffect } from "react";
import { AppContext } from "../App";
import { Link } from "react-router-dom";
import SwiperView from "../swiper-view";
import wsgStyle from "./weather-set-go.css";
import { Swiper } from 'swiper/react';
// import FsLightbox from "fslightbox-react";
import appleStoreLogo from "../../images/appleappstore.png";
import getActiveImg from "../../images/wsg_sc1.png";
import chooseLocationImg from "../../images/wsg_sc2.png";
import viewLocationImg from "../../images/wsg_sc3.png";
import markCompletedImg from "../../images/wsg_sc4.png";
import viewEditImg from "../../images/wsg_sc5.png";
import viewHistoryImg from "../../images/wsg_sc6.png";

function WeatherSetGo(){
    // const swiperEl = document.querySelector('swiper-container');

    const appContext = useContext(AppContext);
    const pageTitleSuffix =  appContext.pageTitleSuffix;

    useEffect(() => {
        appContext.setPageTitle("Weather Set Go " + pageTitleSuffix);
    }, []);
    
    return(
        <section className="wsg">
            <div className="title-section">
                <p style={{paddingBottom: "15px"}}>Weather Set Go</p>
                <Link to="https://apps.apple.com/us/app/weather-set-go/id1625396791"><img className="app-store" src={ appleStoreLogo }/></Link>
            </div>
            <div className="boxes">       
                <div className="box">
                    <img src={ getActiveImg } />
                </div>
                <div className="box get-active">
                    <h3>Get Active</h3>
                    <p>With WeatherSetGo you can check the weather and get a variety of activities to do to get mentally or physically more in shape.</p>
                </div>
                <div className="box ga-mobile">
                    <h3 className="ga-title-mobile">Get Active</h3>
                    <img src={ getActiveImg } />
                    <p>With WeatherSetGo you can check the weather and get a variety of activities to do to get mentally or physically more in shape.</p>
                </div>
                <div className="box choose-location">
                    <h3>Choose your location</h3>
                    <p>Select wherever you are either by zipcode or by city to check your weather! You'll be suggested activities depending on the weather in your area.</p>
                </div>
                <div className="box choose-location-carousel">
                    <SwiperView class="cl-container" images={[chooseLocationImg, viewLocationImg]} />
                </div>
                <div className="box choose-location-mobile">
                    <h3>Choose your location</h3>
                    <SwiperView class="cl-container" images={[chooseLocationImg, viewLocationImg]} />                  
                    <p>Select wherever you are either by zipcode or by city to check your weather! You'll be suggested activities depending on the weather in your area.</p>
                </div>
                <div className="box choose-location-images">
                    <img src={ chooseLocationImg } />
                    <img src={ viewLocationImg } />
                </div>
                <div className="box mark-completed">
                    <div>
                        <h3>Mark Completed</h3>
                        <img src={ markCompletedImg } />
                        <p>Finished an activity?</p>
                        <p>Just write in details if you want and mark it as completed!</p>
                    </div>
                </div>
                <div className="box view-edit">
                    <div>
                        <h3>View/Edit</h3>
                        <img src={ viewEditImg } />
                        <p>Added one by mistake?</p>
                        <p>Just delete it!</p>
                    </div>
                </div>  
                <div className="box wsg-history">
                    <h3>View Your History</h3>
                    <p>See what activities you have done in the past 7 days</p>
                    <img src={ viewHistoryImg } />
                </div>  
                <div className="box wsg-history-mobile">
                    <h3>View Your History</h3>
                    <img src={ viewHistoryImg } />
                    <p>See what activities you have done in the past 7 days</p>
                </div>              
            </div>
        </section>
    );
}

export default WeatherSetGo;