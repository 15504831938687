const workExperience = [
    {
        id : 0,
        jobTitle: "Software Engineer",
        jobCoLocationPeriod: "CatSquared, Arkansas (Remote), July 2022 – January 2023",
        bulletPoints:   [ 
                            [null, "Integrated new app functionality using Xamarin Android, including Views and a Zebra Printer Service.",null],
                            [null, "Used Azure’s DevOps for Sprint scheduled tasks and source control (PRs).", null],                    
                            [null, "Integrated Unit Tests in .Net and Angular", null]
                        ]
    },
    {
        id : 1,
        jobTitle: "Projects",
        jobCoLocationPeriod: "Laredo, TX, 2012 - Present",
        bulletPoints:   [   
                            [null, "Helped develop/update Sheepbuy.com",
                                [
                                    "Frontend – Html, CSS, Bootstrap",
                                    "Backend – CodeIgniter, Symphony, PHP, MySql"
                                ]
                            ],
                            [null,  "Created WeatherSetGo app for iOS", 
                                [
                                    "Developed using – Swift, Realm, JSON, Lottie"
                                ]
                            ]                   
                        ]
    },
    {
        id : 2,
        jobTitle: "General Manager",
        jobCoLocationPeriod: "IMC Recycling Inc, Laredo, TX, 2011 - 2022",
        bulletPoints:   [
                            ["Software", "Assisted in the development of in-house software that weighs trucks using a truck scale and tracks customer data.",
                                [
                                    "Windows Forms, MySql"
                                ]
                            ],
                            [null, "Developed Android/iOS app using Xamarin Forms used as a log for Rolloff container pickups/dropoffs.",
                                [
                                    "Frontend  - Xamarin Forms, C#",
                                    "Backend – Laravel, PHP"
                                ]
                            ],
                            [null, "Developed backend for RollOff app using Laravel.", null],
                            ["Managerial","Interviewed, hired, and trained new staff.",null],
                            [null,"Successfully increased variety of recycling items.",null],
                            [null,"Ensure all recycling permits were on file and up to date.",null],
                            [null,"Attended industry tradeshows to maintain knowledge and network.",null],
                            [null,"Worked on improvement of profitability and reduce the unnecessary company expenses",null]
                        ]
    } 
    ,
    {
        id : 3,
        jobTitle: "Co-founder and IT Agent",
        jobCoLocationPeriod: "TechBros Computer Services, Laredo, TX, 2009 - 2010",
        bulletPoints:   [
                            [null, "Developed 2 websites for local restaurants using html and php", null],
                            [null, "Fixed and gave maintenance to business and individual computers.", null],
                            [null, "Provided professional IT services to businesses.", null]
                        ]
    }
];

const education = [
    {
        id : 0,
        jobTitle: "Instituto Tecnologico de Estudios Superiores Campus Monterrey",
        jobCoLocationPeriod: "Bachelors of Engineering in Computer Science 2008",
        bulletPoints: []
    }
];

const skills = [
    {
        id : 0,
        jobTitle: "Programming Languages and Tools",
        jobCoLocationPeriod: "",
        bulletPoints: [ 
                        [null, "Swift", null],
                        [null, "Java", null],
                        [null, "Javascript", null],
                        [null, "JQuery", null],
                        [null, "C#", null],
                        [null, "C++", null],
                        [null, "PHP", null],
                        [null, "Laravel", null],
                        [null, "HTML", null],
                        [null, "CSS", null],
                        [null, "MySQL", null],
                        [null, "MongoDB", null],
                        [null, "Realm", null],
                        [null, "Git", null],
                        [null, "Proto.io and Adobe XD (prototyping)", null],
                        [null, "Xamarin Forms", null],
                        [null, "Xamarin Android", null],
                        [null, "Prism", null],
                        [null, "MVVM adn MVC", null],
                        [null, "React", null]
        ]
    },
    {
        id : 1,
        jobTitle: "Languages",
        jobCoLocationPeriod: "",
        bulletPoints: [  
                        [null, "Fluent English", null],
                        [null, "Fluent Spanish", null]
        ]
    }    
];

export { workExperience, education, skills };