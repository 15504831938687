import React from "react";

function SwiperView(props){

    function notEnclosed(){
       return (
            <swiper-container style={{
                                    "--swiper-navigation-color": "orange", "--swiper-navigation-sides-offset": "0px", "--swiper-navigation-size": "66px",
                                    "--swiper-pagination-color": "orange", "--swiper-pagination-bullet-horizontal-gap": "12px", "--swiper-pagination-bullet-size": "18px"
                                    }} 
                        slides-per-view="1" speed="500" loop="false" css-mode="true" navigation={false} pagination={true} space-between={50}>
                {/* map images */}
                { props.images.map(svSlide) }
            </swiper-container>
       );
    }

    function enclosed(){
        return (
            <div className={props.class}>
                { notEnclosed() }
            </div>
        );
    }

    function svSlide(data){
        return(
            <swiper-slide key={data}><img src={ data }/></swiper-slide> 
        );
    }

    return(props.class ? enclosed() : notEnclosed());
}

export default SwiperView;