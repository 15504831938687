import React, { useContext, useEffect } from "react";
import { AppContext } from "../App";
import { Link } from "react-router-dom";
import portfolioStyle from "./portfolio.css"
import wsgAppThumbnail from "../../images/wsg_icon.png";
import wsgPortfolio from  "../../images/wsg_sc1.png";
import containersIcon from "../../images/containers_icon.png";
import containersPortfolio from "../../images/containers_portfolio.png";
import sheepbuyLogo from "../../images/sheepbuy.png"

function Portfolio(){

    const appContext = useContext(AppContext);
    const pageTitleSuffix =  appContext.pageTitleSuffix;

    useEffect(() => {
        appContext.setPageTitle("Portfolio " + pageTitleSuffix);
    }, []);
    
    const wsgText = 
        `WeatherSetGo is an app designed to make 
        people more active. This could be either mentally or physically. 
        Depending on the current weather for the user this app will randomly 
        generate activities to try out. The activities can be completed and 
        a log is recorded so the user can keep track of what activities he/she 
        have finished. This app was developed using Swift, Realm and Firebase. 
        MVC design pattern was also implemented`;

    const containersText = 
        `This app helps Rolloff drivers at IMC Recycling to keep a log of when and 
        where containers were picked up, dropped off or swapped. User can check data 
        from past months and add new customers to the customer list. App was developed
        using Xamarin Forms, C#, Laravel, and MySql. MVVM design pattern was also implemented 
        with Prism`;

    const sheepbuyText = 
        `Sheepbuy is an online ecommerce website designed to connect sellers 
        and buyers directly. I contributed with front-end and back-end 
        development using html, css, javascript, codeigniter, mysql. This is 
        an ongoing project.`;

    return(
        <section className="portfolio">
            <div className="boxes">
                <div className="box">
                    <h1 className="title">Portfolio</h1>
                </div>
                <div className="box"></div>
                {/* Weather Set Go */}
                <div className="box project">
                    <div>
                        <h1>Weather Set Go</h1>
                        <p>iOS</p>
                        <img className="project-thumbnail" src={ wsgAppThumbnail } />
                        <p className="description">{ wsgText }</p>
                        <Link className="project-link" to="/weather-set-go"><p>Project Page</p></Link>
                    </div>
                </div>
                <div className="box">
                    <img className="project-img" src={wsgPortfolio} />
                </div>            
                <div className="custom-line" />         
                {/* Containers */}
                <div className="box project">
                    <div>
                        <h1>IMC Container Driver Log</h1>
                        <p>Cross-platform iOS/Android</p>
                        <img className="project-thumbnail" src={containersIcon} />
                        <p className="description">{ containersText }</p>
                        <Link className="project-link" to="/containers"><p>Project Page</p></Link>
                    </div>
                </div>
                <div className="box">
                    <img className="project-img-container" src={containersPortfolio} />
                </div>  
                <div className="custom-line cl-2" />  
                {/* sheepbuy */}
                <div className="box sheepbuy">
                    <div>
                        <img className="sheepbuy-logo" src={sheepbuyLogo} />
                        <p>ecommerce website</p>
                        <p className="description">{ sheepbuyText }</p>
                        <Link className="project-link" to="https://www.sheepbuy.com/"><p>Project Page</p></Link>                    
                    </div>
                </div>                                        
            </div>
        </section>
    );
}

export default Portfolio;