import React, { useContext, useEffect } from "react";
import { AppContext } from "../App";
import headerStyle from "./home.css";
import profilePic from "../../images/profile.png";
import CircularButton from "../circular-button/circular-button";


function Home(){

    const appContext = useContext(AppContext);
    const pageTitleSuffix =  appContext.pageTitleSuffix;

    useEffect(() => {
        appContext.setPageTitle("Home " + pageTitleSuffix);
    }, []);

    return(
        <section className="home content">
            <div>
                <img className="profilePic" src={profilePic} />
            </div>
            <div>
                <article className="greeting">
                    <p>Hello, I'm Eduardo</p>
                    <p>Passionate web and mobile developer</p>
                    <p>I create front and back-end web, iOS and Android applications. My aim is to build reliable and scalable applications and bring great ideas to life.</p>
                </article>
                <div className="circle-button-group">
                    <div className="cb-box">
                        <CircularButton className="btngroupc1" title="Resume" type="circle1" link="/resume"/>                        
                    </div>
                    <div className="cb-box">
                        <CircularButton className="btn-group-c2" title="Portfolio" type="circle2" link="/portfolio"/>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Home;